import React, { useEffect, useState } from 'react';

import App from './App';
import Loader from './Components/Loader';

function LoadFile() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Simulating data loading
      setTimeout(() => {
        setLoading(false);
      }, 1000); // 3-second delay
    }, []);
  return (
    <div>
    {loading ? <Loader />:<App></App>}
    </div>
  )
}

export default LoadFile
