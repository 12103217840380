import "./Layer_one.css";

import Electronics from "./assets/Electronics.jpg";
import Fashion from "./assets/Fashion.jpg";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import Manufacture from "./assets/manfacturing.jpg";
import React from "react";
import construction from "./assets/construction.jpg";
import furniture from "./assets/furniture_sofa.jpg";

function Layer_one() {
  const cardData = [
    { id: 1, title: "Electronics", image: Electronics },
    { id: 2, title: "Manufacturing", image: Manufacture },
    { id: 3, title: "Furniture", image: furniture },
    { id: 4, title: "Fashion", image: Fashion },
    { id: 5, title: "Construction", image: construction },
    { id: 6, title: "Agencies", image: Electronics },
  ];

  return (
    <>
      <div className="layer-1-container">
        <div className="layer-1-head">
          <h1 className="layer-1-heading-1">
            Visualize and explain better using interactive 3D and AR
          </h1>
        </div>
        <div className="layer-1-para">
          <p className="layer-1-paragraph">
            XM3DView is an online platform designed to create and manage
            interactive 3D and AR designs, aimed at inspiring, educating, and
            solving problems.{" "}
          </p>
        </div>
        <div className="layer-1-demo">
          <Link to="/book-demo" style={{ textDecoration: "none" }}>
            {" "}
            <button className="button">Book a Demo</button>
          </Link>
        </div>
      </div>

      <div className="video-container ">
        <iframe
          className="video vimeo-1"
          src="https://player.vimeo.com/video/1041945235?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1&controls=0"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title="Vimeo Video"
        ></iframe>
      </div>

      <div className="layer-2-container">
        <div className="layer-2-small-head">
          <h4 className="layer-2-small-heading">powerful features</h4>
        </div>
        <div className="layer-2-head">
          <h2 className="layer-2-heading">
            Everything required for your 3D workflow
          </h2>
        </div>
        <div className="layer-2-para">
          <p className="layer-2-paragraph">
            XM3DView provides a unified platform for modeling, importing,
            editing, collaborating, showcasing, and publishing 3D and AR
            designs. It is used by professionals like designers, engineers,
            product managers, and marketers to drive innovation and
            collaboration.
          </p>
        </div>
      </div>
      <div className="video-container vimeo-1">
        <iframe
          className="video"
          src="https://player.vimeo.com/video/1041950620?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1&controls=0"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title="3D House"
        ></iframe>
      </div>

      <section className="features-section">
        <h2 className="features-heading">
          Innovate and Collaborate with XM3DView
        </h2>
        <div className="features-container">
          <div className="feature-collaboration">
            <h3 className="feature-title">Collaboration</h3>
            <p className="feature-description">
              Functional attributes for interaction and collaboration. Seamless
              access to projects from any device. Duplication of files,
              in-design feedback, and straightforward integration of the entire
              3D scene into presentations or productivity applications.
            </p>
          </div>
          <div className="feature-security">
            <h3 className="feature-title">Security</h3>
            <p className="feature-description">
              Business workspaces allow company managers to assign and manage
              user roles, project permissions, and other functionalities that
              enhance team efficiency while maintaining security.
            </p>
          </div>
          <div className="feature-organization">
            <h3 className="feature-title">Organization</h3>
            <p className="feature-description">
              Establish dedicated workspaces for each project, client, or
              department. Expand as needed. Save your designs and retrieve them
              directly from the canvas. Share project URLs with anyone.
              Transform it into your all-in-one 3D design center.
            </p>
          </div>
        </div>
        <div className="cta-button-container">
          <Link to="/faq" style={{ textDecoration: "none" }}>
            <button className="button">Discover more business solutions</button>
          </Link>
        </div>
      </section>

      <section className="showcase-section">
        <h2 className="showcase-heading">Don’t just showcase. Explain!</h2>
        <p className="showcase-description">
          XM3DView transforms product ideas into fully realized experiences by
          integrating animations, interactions, and various UI choices to convey
          concepts and accomplish your objectives.
        </p>
        <div className="showcase-button-container">
          <Link to="/" style={{ textDecoration: "none" }}>
            <button className="showcase-button">
              Learn about interactions and animations
            </button>
          </Link>
        </div>
      </section>

      <div className="video-container vc-1 vimeo-1">
      <iframe
      className="video"
      src="https://player.vimeo.com/video/1041949290?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1&controls=0"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      title="Sofa Configurator"
    ></iframe>
    
      </div>

      <section className="copy-section">
        <p className="copy-subheading">communication and sharing</p>
        <h2 className="copy-heading">
          Dive Into a 3D Viewer – Engage, Explore, Interact!
        </h2>
        <p className="copy-description">
          Forget about sending files or email attachments; just use one link.
          Click the share button, and your 3D designs will be easy to view on
          any device, including VR glasses.
        </p>
        <h5 className="copy-head-5">
          Try it now! click the link and insert it into any online productivity
          tool, presentation, or chat.
        </h5>

        <a href="https://varahousing.vercel.app/" target="new_blank">
          {" "}
          <div className="copy-button-container">
            <button className="copy-button">Link</button>
          </div>
        </a>
      </section>

      <div className="iframe-container ic-1">
        <iframe
          className="iframe"
          src="https://varahousing.vercel.app/"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="Embedded Content"
        ></iframe>
      </div>

      <div className="industries-container">
        <h2 className="industries-heading">
          Creative solutions for all industries
        </h2>
        <div className="industries-cards">
          {cardData.map((card) => (
            <div
              key={card.id}
              className={`card ${card.title
                .toLowerCase()
                .replace(/\s/g, "-")}-card`}
            >
              <img src={card.image} alt={card.title} className="card-image" />
              <div className="card-info">
                <p className="industry-label">INDUSTRY</p>
                <h3 className="card-title">{card.title}</h3>
                <Link to="/book-demo">
                  <button className="view-demo-btn">View the demo</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Layer_one;
