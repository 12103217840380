// Components/NotFound.js
import React from 'react';
import './NotFound.css'; // Import the CSS file

function NotFound() {
  return (
    <div className="not-found-container">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <a href="/" className="not-found-button">Go Back to Home</a>
    </div>
  );
}

export default NotFound;
