import './Bookdemo.css';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Background from './assets/Background.jpeg';
import { conf } from "../../config"
const Bookdemo = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      Occupation: '',
      choice: '',
      message: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'First name should only contain letters')
        .max(15, 'Must be 15 characters or less')
        .required('First name is required'),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Last name should only contain letters')
        .max(15, 'Must be 15 characters or less')
        .required('Last name is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      phoneNumber: Yup.string()
        .matches(/^[0-9]+$/, 'Must be a number')
        .min(10, 'Phone number must be at least 10 digits')
        .required('Phone number is required'),
      Occupation: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Occupation should only contain letters')
        .required('Occupation is required'),
      choice: Yup.string().required('Please select a topic'),
      message: Yup.string().required('Message is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await  fetch(`${conf.server}/api/contactus`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          resetForm();
          setIsSubmitted(true);
          setSubmissionError(false);
        } else {
          setSubmissionError(true);
        }
      } catch (error) {
        console.error('Error submitting the form:', error);
        setSubmissionError(true);
      }
    },
  });

  const renderInputField = (name, placeholder, type = 'text') => (
    <div className="form-group">
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        aria-label={placeholder}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        className={`input-${name}`}
      />
      {formik.touched[name] && formik.errors[name] && (
        <div className="error-message">{formik.errors[name]}</div>
      )}
    </div>
  );

  return (
    <>
      <div className="bookdemo-container">
        <h1 className="title">Let's Talk</h1>
        <div className="form-wrapper">
          <div className="business-inquiry">
            <h2 className="contact-heading">Business Inquiry</h2>
            <form className="inquiry-form" onSubmit={formik.handleSubmit}>
              {renderInputField('firstName', 'First Name')}
              {renderInputField('lastName', 'Last Name')}
              {renderInputField('email', 'Enter your email address', 'email')}
              {renderInputField('phoneNumber', 'Phone Number')}
              {renderInputField('Occupation', 'Occupation')}

              <div className="form-group">
                <select
                  name="choice"
                  className="select-topic"
                  aria-label="Select a topic"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.choice}
                >
                  <option value="">Which topic best fits your needs?</option>
                  <option value="sales-demo">Request a sales demo or talk to sales</option>
                  <option value="educators-students">Educators and students</option>
                  <option value="partnership">Partnership</option>
                </select>
                {formik.touched.choice && formik.errors.choice && (
                  <div className="error-message">{formik.errors.choice}</div>
                )}
              </div>

              <div className="form-group">
                <textarea
                  name="message"
                  placeholder="Your message"
                  className="textarea-inquiry"
                  aria-label="Your message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
                {formik.touched.message && formik.errors.message && (
                  <div className="error-message">{formik.errors.message}</div>
                )}
              </div>

              <button
                type="submit"
                className="submit-btn"
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
            </form>

            {isSubmitted && (
              <div className="success-message">
                Form submitted successfully!
              </div>
            )}
            {submissionError && (
              <div className="error-message">
                Failed to submit the form. Please try again later.
              </div>
            )}
          </div>

          <div className="book-demo">
            <h2 className="book-demo-head">Book a Demo</h2>
            <p className="book-demo-para">
              Experience our product and secure your spot now—limited slots
              available!
            </p>
            <div className="demo-image">
              <img
                className="bg-image"
                src={Background}
                alt="Book Demo Background"
              />
            </div>
            <Link to="/book-demo">
              <button
                onClick={handleLinkClick}
                className="schedule-call-btn"
              >
                Schedule a Call
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Bookdemo;
