import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Cookie_Banner = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    // Check if cookies have already been accepted
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted) {
      setCookiesAccepted(true);
    }
  }, []);

  const acceptCookies = () => {
    // Set cookiesAccepted to true and hide the banner
    localStorage.setItem("cookiesAccepted", "true");
    setCookiesAccepted(true);
  };

  if (cookiesAccepted) {
    return null; // If cookies are already accepted, don't show the banner
  }

  return (
    <div style={bannerStyles} role="alertdialog" aria-label="Cookie consent banner">
      <p style={textStyles}>
        We use cookies to enhance your experience on our website. By continuing to browse, you agree to our{" "}
        <Link to="/cookie-policy" style={linkStyles}>
          Cookie Policy
        </Link>.
      </p>
      <button onClick={acceptCookies} style={buttonStyles} aria-label="Accept cookies">
        Accept
      </button>
    </div>
  );
};

// Inline styles for the banner, text, link, and button
const bannerStyles = {
  position: "fixed",
  bottom: "0",
  left: "0",
  width: "100%",
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  backgroundColor: "#F5F5F5",
  color: "#333",
  padding: "15px 10px",
  textAlign: "center",
  zIndex: "1000",
  boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
};

const textStyles = {
  color: "#595959",
  margin: "0 auto",
  maxWidth: "800px",
  lineHeight: "1.5",
};

const linkStyles = {
  color: "#6D00FF",
  textDecoration: "underline",
  fontWeight: "bold",
};

const buttonStyles = {
  fontFamily: "Inter, sans-serif",
  fontSize: "16px",
  backgroundColor: "#6D00FF",
  color: "white",
  borderRadius: "8px",
  padding: "12px 20px",
  border: "none",
  cursor: "pointer",
  marginTop: "10px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  transition: "background-color 0.3s ease",
};

export default Cookie_Banner;
