import "./Augment.css";

import AR from "./assets/AR.jpg";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import Manfacture from "./assets/Manfacture.png";
import React from "react";
import ar1 from "./assets/sofa_1.png";
import ar2 from "./assets/sofa_2.png";
import e_sofa from "./assets/ecomerce-sofa.png";
import electronics from "./assets/electronics.png";
import furniture from "./assets/Furniture.png";
import grid_1 from "./assets/grid-1.png";
import grid_2 from "./assets/grid-2.jpg";
import grid_3 from "./assets/grid-3.jpg";

function Augment() {
  return (
    <>
      <div>
        <div className="container">
          <section className="heading">
            <h1>Create exciting and interactive experiences using Web AR.</h1>
            <p className="heading-p-1">
              Transform your designs and elevate your visual experience with
              Xm3dview, the platform for 3D and Augmented Reality. No need for
              app downloads or software installations – just access it through
              your smartphone or AR glasses.
            </p>
            <Link to="/book-demo" style={{ textDecoration: "none" }}>
              <button>Book a Demo</button>
            </Link>
          </section>
          <section className="AR">
            <img className="ar-image" src={AR} alt="AR_IMAGE" />
            <div className="cards">
              <p className="ar-p">
                “AR will impact companies in all industries. It will change how
                we learn, make decisions, and interact. It will reshape how
                businesses serve customers, train employees, design products,
                manage their operations, and compete."
              </p>
            </div>
          </section>

          <section className="benefits">
            <div className="benefit-1">
              <div className="text-content">
                <h3>
                  Take advantage of AR at every stage of the design process.
                </h3>
                <p>
                  Get a clearer view and imagine your design ideas beyond just
                  the screen. Rather than creating a costly physical prototype,
                  use a detailed AR model. You can analyze a product's
                  dimensions and limitations by placing the AR model in its real
                  location. Make smarter decisions by getting valuable feedback
                  and collaborating with your team, who can see and inspect
                  details from every angle..
                </p>
              </div>
              <div className="ar-image-container-1">
                <img className="ar-image-1" src={grid_1} alt="" />
              </div>
            </div>
            <div className="benefit-2">
              <div className="ar-image-container-1">
                <img src={grid_2} className="ar-image-1" alt="" />
              </div>

              <div className="text-content">
                <h3>Move your content from digital to virtual.</h3>
                <p>
                  A quick and simple way to start creating AR content with your
                  own 3D designs. Xm3dview can import many standard file types
                  used in the industry, including: OBJ, STL, FBX, GLTF, GLB,
                  DAE, IGES, STEP and STP
                </p>
              </div>
            </div>

            <div className="benefit-3">
              <div className="text-content">
                <h3>Get your brand ready for the future.</h3>
                <p>
                  Establish your branding as an AR experience at events,
                  exhibitions, or outdoor advertising. The quickest way to
                  enhance your marketing campaign with Augmented Reality is by
                  integrating a fully customized AR viewer that features your
                  branding, including a custom AR icon, QR code, and domain.
                  This viewer will work seamlessly on AR devices, including the
                  Apple Vision Pro. Begin developing your Augmented Reality
                  marketing strategy today.
                </p>
              </div>
              <div className="ar-image-container-1">
                <img src={grid_3} className="ar-image-1" alt="" />
              </div>
            </div>
          </section>

          <section className="animations">
            <h4>ANIMATIONS</h4>
            <h2>Demonstrate the features with animations in AR.</h2>
            <p className="ani-p">
              Make stunning looped animations in Xm3dview that will
              automatically convert to AR—no coding required.
            </p>
            <Link
              to="/faq"
              style={{ textDecoration: "none", color: "#6D00FF" }}
            >
              <button className="showcase-button">
                Learn more about animations
              </button>
            </Link>
          </section>

          <section className="video-section vimeo-1">
          <iframe
  className="video"
  src="https://player.vimeo.com/video/1041951446?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1&controls=0"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
  title="AR Video"
  style={{ top: "0", left: "0" }}  // You can leave the style as-is or adjust it as needed
></iframe>

          </section>

          <section className="Augmented-reality-sec">
            <h2>Augmented Reality Examples</h2>
            <section className="Augmented-reality">
              <div className="aug-ex">
                <img src={Manfacture} alt="Materials & Textures" />
                <h3>AR in manufacturing</h3>
                <p className="aug-para">
                  Share a link with your B2B prospects that features a live,
                  customizable demo with an AR preview. This enables them to see
                  how the solution will fit in the space where it will be
                  installed.
                </p>
                <Link
                  to="https://varahousing.vercel.app/"
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <button>View Demo</button>
                </Link>
              </div>
              <div className="aug-ex">
                <img src={electronics} alt="Materials & Textures" />
                <h3>AR in electronics</h3>
                <p className="aug-para">
                  Incorporate Augmented Reality at each stage of your product
                  development, starting from brainstorming and presenting to
                  your team with tools like Trello, all the way to launching it
                  on your campaign website for the public.
                </p>
                <Link to="/book-demo" style={{ textDecoration: "none" }}>
                  <button>View Demo</button>
                </Link>
              </div>
              <div className="aug-ex">
                <img src={furniture} alt="Materials & Textures" />
                <h3>AR in furniture</h3>
                <p className="aug-para">
                  Since IKEA launched their AR app years ago, all furniture
                  stores can now use AR preview technology on their online
                  shops, like BigCommerce or Magento. You don’t need to install
                  any apps.
                </p>
                <Link
                  to="https://myfirstdiamond.fr/ARsofa/"
                  target="new"
                  style={{ textDecoration: "none" }}
                >
                  <button>View Demo</button>
                </Link>
              </div>
            </section>
          </section>

          <section className="tips-desc">
            <h2>
              Offer more engaging, realistic, and innovative experiences that
              perform well and create lasting impressions.
            </h2>
            <section className="tips">
              <div className="tip">
                <h4>Display prototypes using AR.</h4>
                <p>
                  Receive quicker approvals for any product or package designs.
                  Allow clients to view a realistic prototype from anywhere.
                </p>
              </div>
              <div className="tip">
                <h4>Execute 360-degree campaigns.</h4>
                <p>
                  Digital and virtual content that can be shared instantly, from
                  posting images of your AR creations to embedding them anywhere
                  on the web.
                </p>
              </div>
              <div className="tip">
                <h4>Provide innovative solutions.</h4>
                <p>
                  Perform a thorough analysis of a product's dimensions and
                  limitations by positioning the AR model in its actual
                  location.
                </p>
              </div>

              <div className="tip">
                <h4>Increase e-commerce revenue.</h4>
                <p>
                  Impact customers choices during their shopping experience.
                  Reduce product returns and enhance engagement on the website.
                </p>
              </div>
              <div className="tip">
                <h4>Quicker time to market. </h4>
                <p>
                  A visual and spatial reference can help speed up work
                  processes and make it quicker to launch a product line.
                </p>
              </div>
              <div className="tip">
                <h4>A thorough presentation</h4>
                <p>
                  A comprehensive concept showcase that uses 3D and AR to
                  display your designs and ideas with creativity and innovation.
                </p>
              </div>
            </section>
          </section>

          <section className="businesses">
            <h2>Augmented Reality in the business sector</h2>
            <div className="bus-1">
              <div className="text-content">
                <h3>
                  Streamlined AR Integration Enhances Customer Experience with
                  Xm3dview
                </h3>
                <p>
                  It's really simple to get results with Xm3dview. No more
                  back-and-forth emails during setup. We can access it online
                  with just a few clicks, and our customers enjoy placing Sharp
                  products in their living rooms using AR.
                </p>
                <Link to="/faq" style={{ textDecoration: "none" }}>
                  {" "}
                  <button>Learn more</button>
                </Link>
              </div>
              <div>
                <img src={ar1} alt="" />
              </div>
            </div>
            <div className="bus-2">
              <div>
                <img src={ar2} alt="" />
              </div>

              <div className="text-content">
                <h3>
                  3D and AR Drive Expansion of Italian Furniture Retailer.
                </h3>
                <p>
                  An online retailer of high-quality Italian furniture,
                  expanding into the European market and providing an excellent
                  customer experience through 3D and Augmented Reality.
                </p>
                <Link to="/faq" style={{ textDecoration: "none" }}>
                  <button>Learn more</button>
                </Link>
              </div>
            </div>
          </section>

          <section className="e-commerce">
            <h2>The role of AR in influencing e-commerce purchase</h2>
            <img style={{ width: "100%" }} src={e_sofa} alt="" />
            <div className="ecom-tips">
              <div className="ecom-tip">
                <h4>Virtual individualization</h4>
                <p>80% of shoppers who have used AR like it more than video.</p>
              </div>
              <div className="ecom-tip">
                <h4>Higher cart value.</h4>
                <p>
                  45% of shoppers are willing to pay more if they can try it
                  using AR technology before buying.
                </p>
              </div>
              <div className="ecom-tip">
                <h4>Lower rate of product returns</h4>
                <p>
                  Many companies using AR have reported a 20-45% drop in returns
                  from purchases made with AR guidance in their online stores.
                </p>
              </div>
            </div>
          </section>

          <section className="media-section">
            <div>
              <h2>Creating Augmented Reality without any development work</h2>
            </div>
            <div className="threed-video-containers vimeo-3">
            <iframe
              className="threed-videos"
              src="https://player.vimeo.com/video/1041949290?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1&controls=0"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
              title="3D Configurator Demo - Sofa"
              style={{
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
          </section>

          <section className="last">
            <h2>Augmented Reality technology that works on any device</h2>
            <div className="devices">
              <div>
                <h4>In desktop browsers</h4>
                <p>
                  View, zoom and rotate a realistic 3D composition in your
                  desktop browser and seamlessly transition to mobile AR.
                </p>
              </div>

              <div>
                <h4>On Apple devices</h4>
                <p>
                  Vectary Web AR takes advantage of Apple’s latest ARKit 3 to
                  create an immersive AR experience on iPhone and iPad.
                </p>
              </div>
              <div>
                <h4>On Android devices</h4>
                <p>
                  View your 3D content in real life on supported Android devices
                  with a tap of a button right from your website.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Augment;
