import './Price.css';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import React from 'react';

const Price = () => {
  return (
    <>
      <div className="pricing-section-container">
        {/* Header Section */}
        <header className="pricing-header">
          <h1 className="pricing-heading">Flexible 3D Configurator Pricing</h1>
          <h2 className="pricing-subheading">
            Unlock immersive 3D experiences tailored to your business needs.
          </h2>
          <p className="pricing-description">
            Our pricing is customized based on the industry, category, size, and complexity of your 3D models. Below are estimated ranges to guide you.
          </p>
        </header>

        {/* Pricing Cards Section */}
        <div className="pricing-cards">
          {/* Small Businesses Plan */}
          <div className="pricing-card">
            <h3 className="card-title">For Small Businesses</h3>
            <h4 className="card-price">
              Starting at <span className="price-highlight">$75 USD</span> per product, per month
            </h4>
            <p className="card-description">
              Ideal for showcasing individual products with customizable materials and basic interactions.
            </p>
            <ul className="card-features">
              <li className="feature-item">Up to 10 materials included</li>
              <li className="feature-item">Basic animations and AR support</li>
              <li className="feature-item">High-quality rendering and exports</li>
            </ul>
            <Link to="/book-demo">
              <button className="card-button">Contact for Pricing</button>
            </Link>
          </div>

          {/* Growing Teams Plan */}
          <div className="pricing-card">
            <h3 className="card-title">For Growing Teams</h3>
            <h4 className="card-price">
              Starting at <span className="price-highlight">$199 USD</span> per product, per month
            </h4>
            <p className="card-description">
              Perfect for brands expanding their product lineup with advanced visualizations.
            </p>
            <ul className="card-features">
              <li className="feature-item">Advanced animations and AR functionality</li>
              <li className="feature-item">Customizable lighting and scene controls</li>
              <li className="feature-item">API integration for configurator interactions</li>
            </ul>
            <Link to="/book-demo">
              <button className="card-button">Contact for Pricing</button>
            </Link>
          </div>

          {/* Enterprise Plan */}
          <div className="pricing-card">
            <h3 className="card-title">For Enterprises</h3>
            <h4 className="card-price">
              <span className="price-highlight">Custom Pricing Available</span>
            </h4>
            <p className="card-description">
              Tailored solutions for large-scale deployments with advanced customization and integration.
            </p>
            <ul className="card-features">
              <li className="feature-item">Unlimited products and projects</li>
              <li className="feature-item">Dedicated server instances with CDN</li>
              <li className="feature-item">Role-based team permissions and enterprise-grade security</li>
            </ul>
            <Link to="/book-demo">
              <button className="card-button">Contact Sales</button>
            </Link>
          </div>
        </div>

        {/* Footer Note */}
        <footer className="pricing-footer">
          <p className="pricing-note">
            For a detailed quote, contact us with information about your 3D models, 
            industry, and project requirements. Our team will provide a tailored solution to fit your needs.
          </p>
        </footer>
      </div>
      <Footer />
    </>
  );
};

export default Price;
