import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Augment from "./Components/AR/Augment";
import Bookdemo from "./Components/BookDemo/Bookdemo";
import CookieBanner from "./Components/Cookies/CookieBanner";
import { FAQ } from "./Components/3dConfigurator/FAQ";
import LayerOne from "./Components/Home/Layer_one";
import NotFound from "./Components/PageNotFound/NotFound"; // Import the NotFound component
import Price from "./Components/Pricing/Price";
import React from "react";
import ScrollToTop from "./Components/ScrollToTop";
import ThreedLanding from "./Components/3dConfigurator/Threed_Landing";
import Update from "./Components/Navbar/NavBar";
import CookiePolicy from "./Components/Cookies/CookiePolicy";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <CookieBanner />
        <Update />
        <ScrollToTop></ScrollToTop>

        <Routes>
          <Route path="/" element={<LayerOne />} />
          <Route path="/solutions" element={<LayerOne />} />
          <Route path="/3d" element={<ThreedLanding />} />
          <Route path="/ar" element={<Augment />} />
          <Route path="/pricing" element={<Price />} />
          <Route path="/book-demo" element={<Bookdemo />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />

          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
