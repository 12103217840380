import './Loader.css';

import React from "react";
import logos from './xm.svg';

const Loader = () => {
  return (
    <div className="loader-container">
      <img src={logos} alt="Loading..." className="loader" />
      <h3 className='load-head'>Loading <span className='sp-1'>.</span>  <span className='sp-2'>.</span>  <span>.</span></h3>
    </div>
  );
};

export default Loader;
