import React from "react";

const CookiePolicy = () => {
  return (
    <div style={containerStyles}>
      <h1 style={headingStyles}>Cookie Policy</h1>
      <p style={paragraphStyles}>
        This Cookie Policy explains what cookies are, how we use them, and the choices you have regarding them. Cookies are small data files stored on your device to enhance your experience on our website.
      </p>
      <h2 style={subheadingStyles}>How We Use Cookies</h2>
      <p style={paragraphStyles}>
        We use cookies to improve functionality, analyze website performance, and provide tailored content based on your preferences. Some cookies are essential for the website to operate effectively.
      </p>
      <h2 style={subheadingStyles}>Your Choices</h2>
      <p style={paragraphStyles}>
        You can manage your cookie preferences through your browser settings. However, disabling cookies may affect the functionality of our website.
      </p>
      <p style={paragraphStyles}>
        For more information, please contact us at{" "}
        <a href="mailto:info@varaedtech.com" style={linkStyles}>
          info@varaedtech.com
        </a>.
      </p>
    </div>
  );
};

// Inline styles
const containerStyles = {
  padding: "40px 20px",
  fontFamily: "Inter, sans-serif",
  lineHeight: "1.6",
  maxWidth: "800px",
  margin: "0 auto",
  color: "#333",
};

const headingStyles = {
  fontSize: "2rem",
  color: "#6D00FF",
  marginBottom: "20px",
};

const subheadingStyles = {
  fontSize: "1.5rem",
  color: "#595959",
  marginTop: "20px",
  marginBottom: "10px",
};

const paragraphStyles = {
  fontSize: "1rem",
  marginBottom: "15px",
};

const linkStyles = {
  color: "#6D00FF",
  textDecoration: "underline",
};

export default CookiePolicy;
